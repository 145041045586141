<template>
    <div class="en_add">
        <el-card>
            <el-form ref="form" :model="form" :rules="rule" label-width="100px" :inline="false" size="normal">
                <el-form-item label="商品名称" prop="name">
                    <el-col :span="8">
                        <el-input v-model="form.name" show-word-limit />
                    </el-col>
                </el-form-item>
                <el-form-item label="类型">
                    <el-select v-model="form.type" placeholder="状态" size="medium">
                        <el-option :label="item" :value="+index" v-for="(item, index) in typeList" :key="index" />
                    </el-select>
                </el-form-item>
                <el-form-item label="优惠券ID" v-if="form.type == 2">
                    <el-col :span="8">
                        <el-input v-model="form.couponId"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="体验劵ID" v-if="form.type == 3">
                    <el-col :span="8">
                        <el-input v-model="form.icId"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="消耗积分" prop="bonus">
                    <el-col :span="8">
                        <el-input v-model="form.bonus" />
                    </el-col>
                </el-form-item>
                <el-form-item label="消耗帆币" prop="bonus">
                    <el-col :span="8">
                        <el-input v-model="form.coin" />
                    </el-col>
                </el-form-item>
                <el-form-item label="商品数量" prop="number">
                    <el-input-number v-model="form.number" :step="1"></el-input-number>
                    <template v-if="form.type == 1">
                        <br />
                        <div class="waring" style="margin-top: 10px;">虚拟商品数量以导入的卡密数量为准</div>
                    </template>
                </el-form-item>
                <el-form-item label="商品图片" prop="picture">
                    <imgups :action="action" :limit="99999" :list.sync="form.picture" />
                </el-form-item>
                <el-form-item label="商品描述" prop="picture">
                    <Tiny :value="form.desc" @input="change" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="medium" @click="onSubmit">{{ id ? "修改" : "提交" }}</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import imgup from "../../components/upImg/index.vue";
import rules from "../../utils/rules";
import imgups from "../../components/uplist/index.vue";
import { productType } from "@/utils/dictionary";
export default {
    components: {
        imgup,
        imgups,
    },
    data() {
        return {
            id: null,
            form: {
                name: "",
                picture: [],
                logo: "",
                desc: "",
                number: 0,
                bonus: "",
                type: 0,
            },

            rule: {
                name: [rules.req("请输入商品名称")],
                fullName: [rules.req("请输入企业全称")],
                logo: [rules.req("请输入企业logo")],
                city: [rules.req("请输入省份和城市")],
                people: [rules.req("请输入公司人事")],
                financing: [rules.req("请输入融资情况")],
                type: [rules.req("请输入类型")],
                business: [rules.req("请输入行业")],
                serverArea: [rules.req("请输入业务区域")],
                companyDesc: [rules.req("请输入企业介绍")],
                intro: [rules.req("请输入企业简介")],
            },
            action: {
                action: "bonus",
            },
            province: {},
            city: {},
            baseurl: this.$store.state.env.cos,
            yu: "/" + this.$store.state.env.domain,
            cardList: [],
            typeList: productType,
        };
    },
    computed: {},
    created() {
        sessionStorage.setItem("action", "bonus");
        this.getform();
        this.getEnv();
    },
    methods: {
        change(val) {
            this.form.desc = val;
        },

        async onSubmit() {
            var url;
            if (this.id) {
                url = "/admin/BonusMall/productEdit";
            } else {
                url = "/admin/BonusMall/productAdd";
            }
            var arr = this.form.picture.map((item) => item.url);
            var { data: res } = await this.$http.post(url, {
                ...this.form,
                id: this.id,
                picture: arr.toString(),
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.$router.push("/bonus_mall");
            }
        },
        async getform() {
            if (!this.$route.query.id) {
                return;
            }
            this.id = this.$route.query.id;
            const { data: res } = await this.$http.get("/admin/BonusMall/getProductById?id=" + this.id);
            if (res.errorCode == 200) {
                this.form = res.data;
                this.form.picture = res.data.picture.split(",").map((item) => {
                    return {
                        name: item,
                        url: item,
                    };
                });
            }
        },
        async getEnv() {
            var { data } = await this.$http.get("/admin/UserCard/getList");
            //   console.log(data);
            this.cardList = data.data.list;
        },
        setImg(val) {
            this.form.picture = val;
        },
    },
};
</script>

<style lang="less">
.en_add {
    .box {
        display: inline-block;
        width: 300px;
        input {
            border-color: #dcdfe6 !important;
        }
    }
}
</style>
